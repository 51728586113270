
import { defineComponent } from 'vue';
import TableRow from './TableRow.vue';

export default defineComponent({
  components: {
    TableRow,
  },
  props: {
    org: {
      type: String,
      required: true,
    },
    memberCount: {
      type: Number,
      required: true,
    },
  },
});
