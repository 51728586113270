import axios from 'axios';
import { IIncomingInvite } from '../../entities/incomingInvite';

export async function getIncomingInvites(): Promise<IIncomingInvite[]> {
  const { data: invites } = await axios.get<IIncomingInvite[]>('/invites');
  return invites;
}

export async function acceptInvite(id: number): Promise<void> {
  try {
    await axios.post(`/invites/${id}/accept`);
  } catch (err) {
    if (!axios.isAxiosError(err) || err.response?.status !== 404) {
      throw err;
    }
  }
}

export async function rejectInvite(id: number): Promise<void> {
  try {
    await axios.delete(`/invites/${id}`);
  } catch (err) {
    if (!axios.isAxiosError(err) || err.response?.status !== 404) {
      throw err;
    }
  }
}
