
import { mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IIncomingInvite } from '../../entities/incomingInvite';
import { acceptInvite, getIncomingInvites, rejectInvite } from '@/services/steamcord/invites';
import { getOrgs } from '@/services/steamcord/orgs';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import OrganizationCard from '@/components/dashboard/OrganizationCard.vue';
import TableRow from '@/components/dashboard/TableRow.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const invites = await getIncomingInvites();

    const store = useOrgStore();
    if (store.orgs.length === 0 && invites.length === 0) {
      next({ name: 'New Organization', replace: true });
      return;
    }

    next((vm) => {
      (vm as any).setInvites(invites);
    });
  },
  components: {
    OrganizationCard,
    TableRow,
  },
  computed: {
    ...mapWritableState(useMainStore, ['loading']),
    ...mapWritableState(useOrgStore, ['orgs']),
  },
  data() {
    return {
      acceptLoading: false, // TODO: refactor to an array?
      incomingInvites: [] as IIncomingInvite[],
      rejectLoading: false,
    };
  },
  methods: {
    async acceptInvite(id: number) {
      if (this.acceptLoading || this.rejectLoading) {
        return;
      }

      this.acceptLoading = true;
      await acceptInvite(id);
      this.incomingInvites = await getIncomingInvites();

      this.orgs = await getOrgs();

      this.rejectLoading = false;

      // TODO: go to org?
    },
    async rejectInvite(id: number) {
      if (this.acceptLoading || this.rejectLoading) {
        return;
      }

      this.rejectLoading = true;
      await rejectInvite(id);
      this.incomingInvites = await getIncomingInvites();
      this.rejectLoading = false;
    },
    setInvites(invites: IIncomingInvite[]) {
      this.incomingInvites = invites;
    },
  },
  mounted() {
    this.loading = false;
  },
});
