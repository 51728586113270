import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "high-em" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_row = _resolveComponent("table-row")!

  return (_openBlock(), _createBlock(_component_table_row, null, {
    text: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.org), 1)
    ]),
    buttons: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}